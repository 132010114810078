import writeXlsxFile from 'write-excel-file';


async function writeExcel(slots_cors, corsindex, stdsindex, corstds, corHours, nights, corooms, corteas, classeslist, splits, unavailable, title, uid){
    console.log("saving xlsx file");
    console.log(slots_cors);
    
    const m = slots_cors.length;
    const n = slots_cors[0].length;
    //saving the 1st sheet which is raw data
    const data0 = [];
    const columns0 = [];
    const headrow0 = [];
    columns0.push({width:12});
    headrow0.push({});
    for(let j = 1; j < corsindex.size+1; j++){
      columns0.push({width:2*corsindex.get(j).length});
      headrow0.push({type:String, value:corsindex.get(j), wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#FF0000"});
    }
    data0.push(headrow0);
    console.log("checking in progress");
    console.log(stdsindex);
    console.log(corsindex);
    console.log(corstds);
    for(let i = 1; i < stdsindex.size+1; i++){
      let row = [];
      row.push({type:String, value:stdsindex.get(i), wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
      for(let j = 1; j < corsindex.size+1; j++){
        console.log("j",j);
        if(corstds.get(corsindex.get(j)).has(stdsindex.get(i))){
          row.push({type:Number, value:1, wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#FF0000"});
        }
        else{
          row.push({});
        }
      }
      data0.push(row);
    }
    console.log("check log");
    console.log(corsindex);

    //saving the 2nd sheet which has course data like examHours, nights, rooms, and supervising teachers
    const data1 = [];
    const columns1 = [];
    for(let j = 0; j < corsindex.size + 1; j++){
      columns1.push({width:10});
    }
    let labels = ["examHours", "nights", "rooms", "teachers", "classes"];
    console.log("labels", labels.length);
    for(let i = 0; i < labels.length; i++){
      let row = [];
      row.push({type:String, value:labels[i], wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#FF0000"});
      columns1[0].width = Math.max(columns1[0].width, labels[i].length);
      if(i===0){
        for(let j = 1; j < corsindex.size + 1; j++){
            row.push({type:Number, value:Math.floor(corHours.get(corsindex.get(j))/2), wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
        }
      }
      else if(i===1){
        for(let j = 1; j < corsindex.size + 1; j++){
            row.push({type:Number, value:nights.get(corsindex.get(j)), wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
        }
      }
      else if(i===2){
        for(let j = 1; j < corsindex.size + 1; j++){
            let roomsStr = Array.from(corooms.get(corsindex.get(j))).join("|");
            row.push({type:String, value:roomsStr, wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
            columns1[j].width = Math.max(columns1[j].width, roomsStr.length);
        }
      }
      else if(i===3){
        for(let j = 1; j < corsindex.size + 1; j++){
            let teasStr = Array.from(corteas.get(corsindex.get(j))).join("|");
            row.push({type:String, value:teasStr, wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
            columns1[j].width = Math.max(columns1[j].width, teasStr.length);
        }
      }
      else if(i===4){
        for(let j = 1; j < corsindex.size + 1; j++){
            row.push({type:String, value:classeslist[j-1], wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
            columns1[j].width = Math.max(columns1[j].width, classeslist[j-1].length);
        }
      }
      data1.push(row);
    }
    
    //saving the 3rd sheet which has the split(days) data of the timetable format
    console.log("splits", splits);
    const data2 = [];
    const columns2 = [];
    const headrow2 = [];
    columns2.push({width:10});
    headrow2.push({});
    for(let j = 0; j < splits.length; j++){
      columns2.push({width:10});
      headrow2.push({type:String, value: "D".concat((j+1).toString()), wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#FF0000"});
    }
    data2.push(headrow2);
    let labels2 = ["morning", "afternoon", "night"]; 
    for(let i = 0; i < labels2.length; i++){
      let row = [];
      row.push({type:String, value: labels2[i], wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#FF0000"});
      for(let j = 0; j < splits.length; j++){
        row.push({type:Number, value:Math.floor((splits[j][i+1]-splits[j][i])/2), wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
      }
      data2.push(row);
    }   

    //saving the 4th sheet which has the unavailability of teachers
    const head3 = ["teachers", "unavailable timeslots"]
    const data3 = [];
    const columns3 = [];
    const headrow3 = [];
    for(let j = 0; j < head3.length; j++){
      columns3.push({width:head3[j].length});
      headrow3.push({type:String, value: head3[j], wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#FF0000"});
    }
    data3.push(headrow3);
    let uteasit = unavailable.keys(); 
    for(let i = 0; i < unavailable.size; i++){
      let row = [];
      let curtea = uteasit.next().value;
      row.push({type:String, value:curtea, wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
      let teasStr = Array.from(unavailable.get(curtea)).join("|");
      row.push({type:String, value:teasStr, wrap:true, fontSize:10, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#00FF00"});
      data3.push(row);
    }

    //saving the final sheet which is a table
    const data = [];
    const columns = [];
    const headrow = [];
    headrow.push({type:String, value: title, span:n+1, fontSize:20, fontWeight:"bold", height:40, align:"center", alignVertical:"center", backgroundColor:"#FF0000"})
    for(let j = 1; j < n+1; j++){
      headrow.push(null);
    }
    data.push(headrow);
    const daysrow = [];
    daysrow.push(null);
    for(let j = 1; j < n+1; j++){
      daysrow.push({wrap:true, value:"DAY".concat(j.toString()), fontSize:10, fontWeight:"bold"});
    }
    data.push(daysrow);
    columns.push({width:20})
    for(let j = 1; j < n+1; j++){
      columns.push({width:0})
    }
    //data[0] is already headrow
    //data[1] is a row for days
    for(let i = 0; i < m; i++){
      data.push([])
      data[data.length-1].push({wrap:true, value:(Math.floor(i/2)+1).toString(), fontSize:10, fontWeight:"bold"})
      for(let j = 1; j < n+1; j++){
          columns[j].width = Math.max(columns[j].width, Math.max(...Array.from(slots_cors[i][j-1]).map(e => e.length)));
          data[data.length-1].push({wrap:true, fontSize:10, fontWeight:"bold", type:String, value: Array.from(slots_cors[i][j-1]).join("\n")});
      }
    }

    console.log("printing uid");
    console.log(uid);
    //uploading current table file to S3 bucket
    await writeXlsxFile([data0, data1, data2, data3, data], {columns:[columns0, columns1, columns2, columns3, columns], sheets:['students','courses','days','teachers','timetable']})
      .then(resultFile => {
        console.log("result file check");
        console.log(resultFile);
        const formData = new FormData();
        formData.append(
          "file",
          resultFile,
          'output2030.xlsx'
        );
        const requestOptions = {
          method:'POST',
          body: formData
        };
        fetch(`https://tyrano.daalgo.org/file/upload_result_file/${uid}`, requestOptions)
        //fetch(`http://127.0.0.1:8000/file/upload_result_file/${uid}`, requestOptions)
        .then(data => {
          console.log("check message");
          console.log(data);
        });
        console.log("download result xlsx file after saving to db")
        console.log("check result file before");
        console.log(resultFile)
        var a = document.createElement('a');
        var url = window.URL.createObjectURL(resultFile);
        a.href = url;
        a.download = "output2030.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        a.parentNode.removeChild(a);
        console.log("check resultFile after");
        console.log(resultFile);
      });
}//end of xlsxFileSaveHandler

export {writeExcel};