import React from 'react';
import {useEffect} from 'react';
import {useContext} from 'react';
import {FileContext} from '../contexts/Contexts';
import {Button} from '@mui/material';
import {FileDownload} from '@mui/icons-material';
import {ThemeProvider, createTheme} from '@mui/material/styles';
import {readExcel} from './ReadExcelData';
import {writeExcel} from './WriteExcelData';
const theme = createTheme({
  palette:{
    primary: {
      main:'#1e90ff',
    },
    secondary:{
      main:'#ff0000',
    }, 
  },
});
function TableComponent() {
  const {rFile, table, title, setTitle, slots_cors, corsindex, corstds, stdsindex, uid, corHours, nights, corooms, corteas, splits, unavailable, corteaslist, classeslist} = useContext(FileContext);
  useEffect(() => {
    //setTable(document.getElementById('excel-table'));
    document.getElementById('main-div').appendChild(table);
  },[]);
  useEffect(() => {
    if(!table.hasChildNodes()){
      fileChangeHandler();
    }
  },[rFile]);
  //const input = document.getElementById('file-input');
  //const table = document.getElementById('excel-table');
  //write the modified result to the excel file
  async function xlsxFileSaveHandler(){
    writeExcel(slots_cors, corsindex, stdsindex, corstds, corHours, nights, corooms, corteas, classeslist, splits, unavailable, title, uid);
  }//end of xlsxFileSaveHandler
  
  console.log("EPL check");
  //var title = "";
  const slots = [];
  //const slots_cors = [];
  //const corstds =  new Map();
  //const corsindex = new Map();
  //const stdsindex = new Map();
  const cortoslot = new Map();
  const cortimes = new Map();
  const comps = new Map();
  const corcolors = new Map();
  const stack = [];///////
  ///////////////////////////////
  
  //reading data excel file
  async function fileChangeHandler(){
    //////////////////////////////////////////////////
    //need to initialize data but it is not done yet//
    //////////////////////////////////////////////////
    console.log("inside handler");
    console.log("table", table);
    readExcel(slots, stack, cortoslot, cortimes, comps, corcolors, rFile, corstds, corsindex, stdsindex, corHours, nights, corooms, corteas, corteaslist, classeslist, splits, unavailable, slots_cors, setTitle, table);
  }

  return (
    <div className="App-column" id = "main-div">
      <ThemeProvider theme = {theme}>
      <Button onClick = {xlsxFileSaveHandler} variant = "contained" style = {{marginTop:10, marginBottom:10}} color = "primary" startIcon={<FileDownload />}>
      Save
      </Button>
      </ThemeProvider>
      { 
        table != null &&
        <div className = "App-table-head1" style ={{marginTop:10, marginBottom:10}}>{title}</div>
      }
    </div>
  );
}

export default TableComponent;
